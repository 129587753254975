/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Joost Waaijer <joost@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Education controller.
 */
export default class extends Controller {
  static targets = ['input'];

  /**
   * Remove selected filter option.
   *
   * @param {Event} event
   */
  removeFilter(e) {
    const { type, id } = e.params;
    const target = this.inputTargets.find((input) => input.name === type
      && input.value === id.toString());
    target.remove();

    this.element.submit();
  }
}
