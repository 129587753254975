/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Toggle line-clamp controller.
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['text', 'button'];

  /**
   * resizeObserver instance.
   */
  resizeObserver;

  /**
   * Connect the controller with resizeObserver.
   */
  connect() {
    this.resizeObserver = new ResizeObserver((e) => {
      this.isTextClamped(e[0].target);
    });

    this.resizeObserver.observe(this.textTarget);
  }

  /**
   * Disconnect the controller.
   */
  disconnect() {
    this.resizeObserver.disconnect();
  }

  /**
   * Check if text has line-clamp and show button.
   *
   * @param {HTMLElement} el
   */
  isTextClamped(el) {
    if (el.scrollHeight > el.clientHeight) {
      this.buttonTarget.classList.add('is-shown');
    }
  }

  /**
   * Toggle active class.
   */
  toggle() {
    this.element.classList.toggle(this.activeClass);
  }
}
