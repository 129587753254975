/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 * @author Joost Waaijer <joost@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from '@gsap/business';
import { ScrollTrigger } from '@gsap/business/ScrollTrigger';

/**
 * Scrollspy Controller.
 */
export default class extends Controller {
  static classes = ['active'];

  static targets = ['section', 'navlink'];

  /**
   * GSAP context.
   */
  gsapContext = gsap.context(() => {});

  /**
   * Set up GSAP Context and ScrollTrigger.
   */
  connect() {
    this.gsapContext.add(() => {
      this.sectionTargets.forEach((section) => {
        ScrollTrigger.create({
          trigger: section,
          id: section.id,
          start: 'top top+=1',
          end: 'bottom top+=1',
          onToggle: () => this.toggleActiveNav(section),
        });
      });
    });
  }

  /**
   * Disconnect GSAP context.
   */
  disconnect() {
    this.gsapContext.kill();
  }

  /**
   * Toggle class on active nav item
   *
   * @param {Object} activeSection
   */
  toggleActiveNav(activeSection) {
    this.sectionTargets.forEach((section, index) => {
      if (activeSection.id === section.id) {
        this.navlinkTargets[index].classList.add(...this.activeClasses);
      } else {
        this.navlinkTargets[index].classList.remove(...this.activeClasses);
      }
    });
  }
}
