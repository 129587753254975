/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Check test controller
 */
export default class extends Controller {
  static targets = ['input', 'next'];

  /**
   * Toggle next button class.
   */
  change() {
    const checkedInputs = this.inputTargets.some((input) => input.checked === true);
    this.nextTarget.disabled = !checkedInputs;
    this.nextTarget.classList.toggle('disabled', !checkedInputs);
  }
}
