/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['list'];

  /**
   * Scroll to list.
   */
  scrollTo() {
    this.listTarget.scrollIntoView({ behavior: 'smooth' });
  }
}
