/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Tabs Controller
 */
export default class extends Controller {
  static targets = ['content'];

  static classes = ['active'];

  /**
   * Connect the controller
   */
  connect() {
    this.anchors = this.element.querySelectorAll('a[href*="#"]:not(.apply)');
    this.anchors.forEach((anchor) => anchor.addEventListener('click', this.toggle.bind(this)));
  }

  /**
   * Disconnect the controller
   */
  disconnect() {
    this.anchors.forEach((anchor) => {
      anchor.removeEventListener('click', this.toggle.bind(this));
    });
  }

  /**
   * Scroll to the selected tab
   *
   * @param {Event} event
   */
  toggle(event) {
    event.preventDefault();
    const target = event.currentTarget;
    const { href } = target;
    const anchor = href.split('#')[1] || null;

    this.dispatch?.('select', { detail: { id: +event.currentTarget.dataset.id } });

    if (anchor) {
      const element = document.getElementById(anchor);
      if (element) {
        this.anchors.forEach((a) => a.classList.toggle(this.activeClass, a === target));
        this.contentTargets.forEach((c) => c.classList.toggle(this.activeClass, c.id === anchor));
        if (element.tagName === 'SECTION') {
          if (window.innerWidth >= 992) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        throw Error('Scroll anchor not found');
      }
    }
  }
}
