/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['code', 'location', 'date', 'rate'];

  /**
   * Fetch courses.
   *
   * @param {Event} e
   */
  async fetchCourses(e) {
    const field = this.element.querySelector('[name="fields[courseCode]"]');
    const response = await fetch(e.params.url);
    const result = await response.json();

    result.forEach((course) => {
      const option = document.createElement('option');
      option.text = [course.code, course.location, course.date].join(' - ');
      option.value = course.id;
      option.dataset.code = course.code;
      option.dataset.location = course.location;
      option.dataset.date = course.date;
      option.dataset.rate = course.rate;
      field.add(option);
    });
  }

  /**
   * Update course info.
   *
   * @param {Event} e
   */
  updateCourseInfo(e) {
    const { options, selectedIndex } = e.target;
    const option = options[selectedIndex];
    this.codeTarget.value = option.dataset.code;
    this.locationTarget.value = option.dataset.location;
    this.dateTarget.value = option.dataset.date;
    this.rateTarget.value = option.dataset.rate;
  }
}
