/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { useDebounce, useResize } from 'stimulus-use';
import { Controller } from '@hotwired/stimulus';

/**
 * Video controller
 */
export default class extends Controller {
  static debounces = [{ name: 'resize', wait: 500 }];

  static values = { sources: Array };

  src;

  /**
   * Connect to the controller.
   */
  connect() {
    this.src = this.element.src;

    useDebounce(this);
    useResize(this);

    // Init
    this.setSourcesValue();
  }

  /**
   * Resize handler.
   */
  resize() {
    this.setSourcesValue();
  }

  /**
   * Set the sources value based on `matchMedia` breakpoint.
   */
  setSourcesValue() {
    this.sourcesValue.forEach((source) => {
      if (window.matchMedia(source.media).matches && this.src !== source.src) {
        this.src = source.src;
        this.element.src = this.src;
      }
    });
  }
}
