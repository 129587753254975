/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { gsap } from '@gsap/business';

Swiper.use([Autoplay, EffectFade, Pagination]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper', 'prev', 'next', 'progress', 'video'];

  static values = {
    options: Object,
    type: {
      type: String,
      default: 'default',
    },
  };

  /**
   * Swiper instance.
   */
  swiper;

  /**
   * Init.
   */
  initialize() {
    this.swiper = new Swiper(this.wrapperTarget, {
      slidesPerView: 'auto',
      navigation: {
        nextEl: this.hasNextTarget ? this.nextTarget : undefined,
        prevEl: this.hasPrevTarget ? this.prevTarget : undefined,
      },
      ...this.optionsValue,
    });
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();

    if (this.hasVideoTarget) {
      this.videoTarget.play();
    }

    if (this.typeValue === 'video') {
      this.swiper.on('slideChangeTransitionEnd', this.slideChange.bind(this));
    } else {
      this.swiper.on('sliderMove', this.toggleMovingClass.bind(this));
      this.swiper.on('touchEnd', this.toggleMovingClass.bind(this, false));
    }
  }

  /**
   * Destroy.
   */
  disconnect() {
    if (this.typeValue === 'video') {
      this.swiper.off('slideChangeTransitionEnd', this.slideChange.bind(this));
    } else {
      this.swiper.off('sliderMove', this.toggleMovingClass.bind(this));
      this.swiper.off('touchEnd', this.toggleMovingClass.bind(this, false));
    }

    this.swiper.destroy(true);
    this.swiper = undefined;
  }

  /**
   * Handle slide change.
   *
   * @param {Event} event
   */
  slideChange(event) {
    const { activeIndex, previousIndex, slides } = event;
    const nextIndex = activeIndex + 1;
    const isLastSlide = nextIndex === slides.length;

    // Reset progress
    const progressNode = this.progressTargets[previousIndex];
    gsap.set(progressNode, { '--progress': 0 });

    // Pause previous slide video
    const previous = slides[previousIndex];
    const previousVideo = previous.querySelector('video');

    if (previousVideo) {
      previousVideo.pause();
      previousVideo.currentTime = 0;
      previousVideo.removeEventListener('timeupdate', this.trackVideoProgress.bind(this));
      previousVideo.removeEventListener('ended', this.slideTo.bind(this, { params: { index: activeIndex } }));
    }

    // Play active slide video
    const active = slides[activeIndex];
    const activeVideo = active.querySelector('video');

    if (activeVideo) {
      activeVideo.play();
      activeVideo.addEventListener('timeupdate', this.trackVideoProgress.bind(this));
      activeVideo.addEventListener('ended', this.slideTo.bind(this, { params: { index: isLastSlide ? 0 : nextIndex } }));
    }
  }

  /**
   * Track video progress.
   *
   * @param {Event} event
   */
  trackVideoProgress({ target: { currentTime, duration } }) {
    const progressNode = this.progressTargets[this.swiper.activeIndex];
    const progress = (currentTime / duration) * 100;
    // @TODO make smooth with GSAP
    gsap.set(progressNode, { '--progress': progress });
  }

  /**
   * Go to slide.
   *
   * @param {Event} event
   */
  slideTo({ params }) {
    // Reset progress for all nodes
    this.progressTargets.forEach((progressNode) => {
      gsap.set(progressNode, { '--progress': 0 });
    });

    this.swiper.slideTo(params.index || 0);
  }

  /**
   * Toggle moving class.
   *
   * @param {Boolean} force
   */
  toggleMovingClass(force = true) {
    this.wrapperTarget.classList.toggle('is-moving', force);
  }
}
