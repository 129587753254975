/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Simple popup controller
 */
export default class extends Controller {
  static targets = ['popup'];

  static classes = ['active'];

  /**
   * Toggle target class.
   *
   * @param {Event} e
   */
  toggle(e) {
    this.popupTargets.forEach((element) => {
      if (element.dataset.id === e.params.id) {
        if (element.classList.toggle(this.activeClass)) {
          const video = element.querySelector('[data-toggle-target=video]');
          if (video) {
            video.play();
          }
        }
      }
    });
  }
}
