/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Simple toggle controller
 */
export default class extends Controller {
  static targets = ['input', 'video'];

  static classes = ['active'];

  /**
   * Toggle target class.
   *
   * @param {Event} event
   */
  toggle(event) {
    event.preventDefault();
    this.element.classList.toggle(this.activeClass);

    if (this.hasInputTarget) this.inputTarget.focus();
  }
}
