/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import { gsap } from '@gsap/business';
import { ScrollTrigger } from '@gsap/business/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

/**
 * GSAP controller.
 */
export default class extends Controller {
  static values = {
    type: String,
    direction: String,
  };

  /**
   * GSAP context.
   */
  gsapContext = gsap.context(() => {});

  /**
   * Connect.
   */
  connect() {
    // Scroll image animation
    if (this.typeValue === 'image') {
      this.gsapContext.add(() => {
        const target = this.element.querySelector('img');

        if (target) {
          const scaleFactor = 1.1;
          const originalHeight = target.offsetHeight;
          const scaledHeight = originalHeight * scaleFactor;
          const offset = (scaledHeight - originalHeight) / 2;

          gsap.fromTo(target, {
            y: this.directionValue === 'down' ? -offset : offset,
            scale: scaleFactor,
          }, {
            y: this.directionValue === 'down' ? offset : -offset,
            scale: scaleFactor,
            force3D: true,
            scrollTrigger: {
              trigger: this.element,
              scrub: 1,
            },
          });
        }
      });
    }
  }

  /**
   * Disconnect.
   */
  disconnect() {
    this.gsapContext.kill();
  }
}
