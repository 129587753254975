/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for Alert.
 */
export default class extends Controller {
  static targets = ['close'];

  /**
   * Hide alert instance
   */
  hideAlert;

  /**
   * Initialize the controller.
   */
  initialize() {
    this.hideAlert = sessionStorage.getItem('hideAlert');
  }

  /**
   * Connect the controller.
   */
  connect() {
    if (!this.hideAlert) this.element.classList.remove('hidden');
  }

  /**
   * Close the alert
   */
  close() {
    this.hideAlert = sessionStorage.setItem('hideAlert', true);
    this.element.classList.add('hidden');
  }
}
