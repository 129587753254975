/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Overlay controller
 */
export default class extends Controller {
  static targets = ['video'];

  /**
   * Start video.
   */
  startVideo() {
    if (this.hasVideoTarget) {
      this.videoTarget.play();
    }
  }
}
