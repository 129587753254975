/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Jurriën Peters <jurrien@robuust.digital>
 * @author Joost Waaijer <joost@robuust.digital>
 */
import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

/**
 * Simple toggle controller
 */
export default class extends Controller {
  static targets = ['input', 'form', 'result'];

  static classes = ['active'];

  static debounces = ['search'];

  /**
   * connect.
   */
  connect() {
    useDebounce(this);
  }

  /**
   * Search target class.
   *
   * @param {Event} event
   */
  async search(event) {
    event.preventDefault();
    const { value } = event.target;
    this.element.classList.toggle(this.activeClass, value.length > 0);
    this.element.classList.add('is-open', value.length > 0 && !this.element.classList.contains('is-open'));

    if (value.length > 1) {
      this.resultTarget.innerHTML = '';
      const url = new URL(this.formTarget.action);
      url.searchParams.set('q', value);
      url.searchParams.set('isPartial', true);

      const result = await fetch(url);
      const html = await result.text();

      this.resultTarget.innerHTML = html;
    }
  }
}
