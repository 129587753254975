/**
 * ROC van Twente
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'drawer'];

  static values = {
    endpoint: String,
  };

  /**
   * Open drawer
   *
   * @param {Event} Event
   */
  open(e) {
    const { endpoint, theme } = e.params;

    this.drawerTarget.classList.add('drawer-is-open');
    document.body.classList.add('overflow-hidden');

    if (endpoint) {
      this.drawerTarget.classList.add('drawer-loading');
      this.loadContent(endpoint);
    } else {
      this.drawerTarget.classList.add('drawer-content-loaded');
    }

    if (theme) this.drawerTarget.classList.add(theme);
  }

  /**
   * Close drawer.
   */
  close() {
    this.drawerTarget.classList.remove('drawer-is-open');
    this.drawerTarget.classList.remove('drawer-content-loaded');
    document.body.classList.remove('overflow-hidden');
    this.contentTarget.innerHTML = '';
  }

  /**
   * Load drawer content.
   *
   * @param {String} endpoint
   */
  async loadContent(endpoint) {
    const response = await fetch(endpoint);
    const text = await response.text();

    this.drawerTarget.classList.add('drawer-content-loaded');
    this.drawerTarget.classList.remove('drawer-loading');
    this.contentTarget.innerHTML = text;
  }
}
